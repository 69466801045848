'use client';
import * as React from 'react';
import Paper from '@mui/material/Paper';
import { colors } from '@/lib/theme/palette';

type Props = {
  children: React.ReactNode;
};

export default function SearchResultMenu({ children }: Props) {
  return (
    <Paper
      sx={{
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
        width: '100%',
        marginTop: '8px',
        zIndex: 1,
        padding: '32px',
        background: colors.white,
        borderRadius: '16px',
        border: `1px solid ${colors.blue}`,
      }}
    >
      {children}
    </Paper>
  );
}
