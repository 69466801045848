import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type StoreType = {
  history: string[];
};

export type HistoryActions = {
  setHistory: (historyItem: string) => void;
};

export type HistoryStore = StoreType & HistoryActions;

export const useHistoryStore = create<HistoryStore>()(
  persist(
    set => ({
      history: [],
      setHistory: historyItem =>
        set(state => {
          const newHistoryState = [...state.history, historyItem].splice(
            -6,
          ) as string[];
          return {
            history: Array.from(new Set(newHistoryState)),
          };
        }),
    }),
    { name: 'history-store', skipHydration: false },
  ),
);
