import * as React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { colors } from '@/lib/theme/palette';
import CompanyType from '@/shared/apis/companies/types.company';

interface SearchCompaniesProps {
  companies: CompanyType[];
  onHideMenu?: () => void;
}

export default function Companies({
  companies,
  onHideMenu,
}: SearchCompaniesProps) {
  const handleHideMenu = () => {
    if (onHideMenu) {
      onHideMenu();
    }
  };
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 0 16px 0',
        }}
      >
        <Typography
          component="div"
          variant="h5"
          sx={{
            color: colors.black500,
          }}
        >
          Companies
        </Typography>
        <Link href="/library?type=companies" onClick={handleHideMenu}>
          <Typography
            variant="body1"
            sx={{
              color: colors.blue,
            }}
          >
            See all companies
          </Typography>
        </Link>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        {companies.map(company => {
          return (
            <Link
              href={`/company/${company.id}`}
              key={`company${company.id}`}
              onClick={handleHideMenu}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Image
                  src={company.image_path || '/company-default-img.png'}
                  loading="lazy"
                  alt={`Company ${company.name} icon`}
                  width={60}
                  height={60}
                  style={{ objectFit: 'contain' }}
                />
                <Box
                  sx={{
                    padding: '0 0 0 20px',
                  }}
                >
                  <Typography
                    component="div"
                    variant="h4"
                    sx={{
                      padding: '0 0 3px 0',
                      fontWeight: 700,
                      cursor: 'pointer',
                    }}
                  >
                    {company.name}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      flexWrap: 'wrap',
                      '& .category-divider': {
                        '&:last-child': {
                          display: 'none',
                        },
                      },
                    }}
                  >
                    {company.categories.map(category => (
                      <>
                        <Typography
                          key={`category_${category.value}`}
                          component="span"
                          variant="subtitle2"
                          sx={{
                            fontWeight: 400,
                            color: colors.black,
                          }}
                        >
                          {category.name || 'No category'}
                        </Typography>
                        <Typography
                          component="span"
                          className="category-divider"
                          variant="body2"
                          sx={{
                            color: colors.black,
                          }}
                        >
                          ·
                        </Typography>
                      </>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Link>
          );
        })}
      </Box>
    </Box>
  );
}
