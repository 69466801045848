import {
  // TextField,
  OutlinedInput,
  // TextFieldProps,
  OutlinedInputProps,
} from '@mui/material';
import React from 'react';

type DebounceProps = {
  handleDebounce: (value: string) => void;
  debounceTimeout: number;
};

function DebounceInput(props: OutlinedInputProps & DebounceProps) {
  const { handleDebounce, debounceTimeout, ...rest } = props;

  const timerRef = React.useRef<number>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = window.setTimeout(() => {
      handleDebounce(event.target.value);
    }, debounceTimeout);
  };

  return <OutlinedInput {...rest} onChange={handleChange} />;
}

export default DebounceInput;
