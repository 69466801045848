import * as React from 'react';
import Link from 'next/link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { TCategory } from '@/shared/apis/categories/types.categories';
import { colors } from '@/lib/theme/palette';
import { getHighlightedText } from '@/lib/utils';

interface SearchCategoriesProps {
  categories: TCategory[];
  searchQuery: string;
  onHideMenu?: () => void;
}

export default function Categories({
  categories,
  searchQuery,
  onHideMenu,
}: SearchCategoriesProps) {
  const handleHideMenu = () => {
    if (onHideMenu) {
      onHideMenu();
    }
  };
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 0 16px 0',
        }}
      >
        <Typography
          component="div"
          variant="h5"
          sx={{
            color: colors.black500,
          }}
        >
          Categories
        </Typography>
        <Link href="/technologies" onClick={handleHideMenu}>
          <Typography
            variant="body1"
            sx={{
              color: colors.blue,
            }}
          >
            See all categories
          </Typography>
        </Link>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        {categories.map(category => (
          <Link
            href={`/library?categories=${category.value}`}
            key={`category${category.id}`}
            onClick={handleHideMenu}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: 400,
                cursor: 'pointer',
                '& span': {
                  fontWeight: 700,
                },
              }}
            >
              {getHighlightedText(category.name, searchQuery)}
            </Typography>
          </Link>
        ))}
      </Box>
    </Box>
  );
}
