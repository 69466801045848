'use client';

import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';

import Box from '@mui/material/Box';
import { SxProps } from '@mui/material';

import { colors } from '@/lib/theme/palette';
import { useHistoryStore } from '@/lib/store/history.store';

import { SearchService } from '@/services/search.service';

import SearchBox from '@/shared/components/SearchBar/SearchBox';
import SearchResultMenu from '@/shared/components/SearchBar/SearchResultMenu';
import SearchHistory from '@/shared/components/SearchBar/SearchHistory';
import SearchResultsPreview from '@/shared/components/SearchBar/SearchResultsPreview';
import useQueryParams, {
  serializeFormQuery,
} from '@/shared/hooks/useQueryParams.hook';

const defaultStyles: SxProps = {
  p: {
    xs: '4px 4px 4px 16px',
    sm: '6px 6px 6px 19px',
    md: '7px 7px 7px 22px',
    lg: '8px 8px 8px 24px',
    xl: '12px 12px 12px 32px',
  },
  m: {
    xs: '16px 0 0 0',
    sm: '16px 0 0 0',
    md: '16px 0 0 0',
    lg: '16px 0 0 0',
    xl: '32px 0 0 0',
  },

  display: 'flex',
  alignItems: 'center',
  boxShadow:
    '0px 16px 28px -12px rgba(106, 135, 255, 0.20), 0px 6px 64px -4px rgba(106, 135, 255, 0.10)',
  borderRadius: '60px',
  outline: `solid 1px ${colors.white}`,
};

const headerStyle: SxProps = {
  p: {
    xs: '4px 4px 4px 16px',
    sm: '4px 6px 4px 20px',
    xl: '4px 6px 4px 32px',
  },

  height: {
    xs: '40px',
    sm: '48px',
    xl: '60px',
  },
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #E6E9ED',
  borderRadius: '60px',
};

const mobileStyle: SxProps = {
  p: {
    xs: '4px 4px 4px 16px',
    sm: '6px 6px 6px 19px',
    md: '7px 7px 7px 22px',
    lg: '8px 8px 8px 24px',
    xl: '12px 12px 12px 32px',
  },

  display: 'flex',
  alignItems: 'center',
  borderRadius: '0px',
};

type SearchBarProps = {
  variant?: 'default' | 'mobile' | 'desktop' | 'header';
  onHideMenu?: () => void;
};
export default function SearchBar(props: SearchBarProps) {
  const { variant = 'default' } = props;

  const { push } = useRouter();

  const { queries, setQueries } = useQueryParams<{
    query: string;
    type?: string;
    pageCount?: string;
  }>({
    query: '',
  });

  const styles =
    variant === 'header'
      ? headerStyle
      : variant === 'mobile'
        ? mobileStyle
        : defaultStyles;

  const [searchQuery, setSearchQuery] = useState<string>(queries.query);

  const [showMenu, setShowMenu] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const [previewResults, setPreviewResults] = useState({
    categories: [],
    companies: [],
    pdf_resources: [],
  });
  const { setHistory } = useHistoryStore();

  const searchStarted = searchQuery && searchQuery.length >= 3;

  useEffect(() => {
    setSearchQuery(queries.query);
  }, [queries.query]);

  useEffect(() => {
    if (searchStarted) {
      const fetchData = async () => {
        setLoading(true);

        try {
          const results =
            await SearchService.getSearchPreviewResults(searchQuery);
          const { data } = results;

          // const results = await Promise.all([
          //   SearchService.getOpenSearchPreviewResults({
          //     query: searchQuery,
          //     type: 'resources',
          //     pageCount: '3',
          //     ordering: 'num_resources',
          //   }),
          //   SearchService.getOpenSearchPreviewResults({
          //     query: searchQuery,
          //     type: 'companies',
          //     pageCount: '3',
          //     ordering: 'num_resources',
          //   }),
          // ]);

          // const data = {
          //   categories: [],
          //   companies: results[1].data as never[],
          //   pdf_resources: results[0].data as never[],
          // };

          // console.log('results', results);

          setPreviewResults(data);
        } catch (error) {
          console.log('error', error);
        } finally {
          setLoading(false);
        }
      };

      fetchData();

      // if (!showMenu) {
      //   setShowMenu(true);
      // }
    }
  }, [searchQuery]);

  const handleSearch = () => {
    if (!searchQuery) {
      return;
    }

    if (variant === 'header' && window.location.pathname === '/library') {
      setQueries({
        query: searchQuery,
        type: 'resources',
        pageCount: '12',
      });
    } else {
      push(
        `/library?${serializeFormQuery({ type: 'resources', pageCount: '12', query: searchQuery })}`,
      );
    }

    setHistory(searchQuery);
    setShowMenu(false);
  };

  const handleSearchFieldChange = (value: string) => {
    // const value = e.target.value;

    setSearchQuery(value);
  };

  const handleHideMenu = () => {
    if (props.onHideMenu) {
      props.onHideMenu();
      setSearchQuery('');
    }

    setShowMenu(false);
  };

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',

        ...(variant === 'header' && {
          width: {
            xs: '51%',
            lg: '60%',
          },
          m: {
            xs: '0 0 0 0',
            sm: '0 24px',
            xl: '0 32px',
          },
          maxWidth: {
            xs: '506px',
            lg: '830px',
          },
        }),
      }}
    >
      <Box
        sx={{
          position: 'relative',
          zIndex: 1,
        }}
      >
        <SearchBox
          value={searchQuery}
          setShowMenu={setShowMenu}
          handleSearchFieldChange={handleSearchFieldChange}
          handleSearch={handleSearch}
          sx={styles}
          variant={variant}
        />
      </Box>
      {showMenu ? (
        <>
          <SearchResultMenu>
            {!searchStarted ? (
              <SearchHistory />
            ) : (
              <SearchResultsPreview
                loading={loading}
                searchQuery={searchQuery}
                searchData={previewResults}
                onHideMenu={handleHideMenu}
              />
            )}
          </SearchResultMenu>
          <Box
            sx={{
              position: 'fixed',
              width: '100%',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
            onClick={() => setShowMenu(false)}
          />
        </>
      ) : (
        ''
      )}
    </Box>
  );
}
