import * as React from 'react';
import Link from 'next/link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { colors } from '@/lib/theme/palette';
import { useHistoryStore } from '@/lib/store/history.store';

export default function SearchHistory() {
  const { history } = useHistoryStore();

  return (
    <Box>
      <Typography
        component="div"
        variant="h5"
        sx={{
          color: colors.black500,
          padding: '0 0 16px 0',
        }}
      >
        Search history
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        {history.length ? (
          history.map((historyItem, index) => (
            <Link
              key={`history_${index}`}
              href={`/library?query=${historyItem}&type=resources&pageCount=12`}
            >
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 400,
                  cursor: 'pointer',
                }}
              >
                {historyItem}
              </Typography>
            </Link>
          ))
        ) : (
          <Typography
            variant="h4"
            sx={{
              fontWeight: 400,
              cursor: 'pointer',
            }}
          >
            No search history
          </Typography>
        )}
      </Box>
    </Box>
  );
}
