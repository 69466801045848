import * as React from 'react';
import Link from 'next/link';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { colors } from '@/lib/theme/palette';
import ResourceInterface from '@/shared/apis/resources/types.resources.d';

interface SearchResourcesProps {
  resources: ResourceInterface[];
  onHideMenu?: () => void;
}

export default function Resources({
  resources,
  onHideMenu,
}: SearchResourcesProps) {
  const handleHideMenu = () => {
    if (onHideMenu) {
      onHideMenu();
    }
  };
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 0 16px 0',
        }}
      >
        <Typography
          component="div"
          variant="h5"
          sx={{
            color: colors.black500,
          }}
        >
          Resources
        </Typography>
        <Link href="/library?type=resources" onClick={handleHideMenu}>
          <Typography
            variant="body1"
            sx={{
              color: colors.blue,
            }}
          >
            See all resources
          </Typography>
        </Link>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '22px',
        }}
      >
        {resources.map(resource => (
          <Link
            key={`resource${resource.id}`}
            href={`/resource/${resource.id}`}
            onClick={handleHideMenu}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '7px',
              }}
            >
              <Stack direction="row" spacing={1}>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{
                    color: colors.black700,
                  }}
                >
                  {resource.company.name || 'No company'}
                </Typography>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{
                    color: colors.black700,
                  }}
                >
                  ·
                </Typography>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{
                    color: colors.black700,
                  }}
                >
                  {resource.resource_type || 'No type'}
                </Typography>
              </Stack>
              <Typography
                variant="h4"
                sx={{
                  cursor: 'pointer',
                }}
              >
                {resource.name}
              </Typography>
            </Box>
          </Link>
        ))}
      </Box>
    </Box>
  );
}
