import httpClient from '@/lib/client/httpClient';
import { SearchResultsType } from './catalog.service';

export const SearchService = {
  async getOpenSearchPreviewResults(params: {
    query: string;
    type?: 'resources' | 'companies';
    pageCount?: string;
    page?: string;
    keywords?: string;
    resourceType?: string;
    categories?: string;
    ordering?: 'num_resources' | '-num_resources ';
  }): Promise<SearchResultsType> {
    const response = (await httpClient.get('/opensearch', {
      params,
    })) as SearchResultsType;

    return response;
  },

  async getSearchPreviewResults(query: string): Promise<{
    data: {
      categories: never[];
      companies: never[];
      pdf_resources: never[];
    };
  }> {
    return httpClient.get('/search', {
      params: {
        query,
      },
    });
  },
};
